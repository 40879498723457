/* eslint-disable react/prop-types */
import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDelay } from '../../redux/metadata/selectors';

function SelectDelay({ type }) {
  const { t } = useTranslation();

  const delays = useDelay();

  return (
    <Form.Item
      label={type <= 1 ? t('FRONT_MEDICAL_FILE_DELAY') : t('FRONT_MEDICAL_FILE_DELAY_CHECKUP')}
      name="delay"
      rules={[
        {
          required: true,
          message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
    >
      <Select placeholder={t('FRONT_COMMON_SELECT')}>
        {delays.map((p, i) => (
          <Select.Option key={p} value={i}>
            {p}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectDelay;
