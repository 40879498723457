import { Descriptions } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useCountries } from '../../redux/metadata/selectors';
import languages from '../../services/languages';

function PatientInfos({ patient }) {
  const { t } = useTranslation();
  const countries = useCountries();

  const { profile } = patient;
  const language = languages.find((l) => patient.language === l.value);

  return (
    <Descriptions bordered>
      <Descriptions.Item label={t('FRONT_USER_EMAIL')} span={3}>
        {patient.email}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_FIRST_NAME')} span={3}>
        {patient.firstname}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_LAST_NAME')} span={3}>
        {patient.lastname}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_MOBILE_PHONE')} span={3}>
        {profile.phone}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_ADDRESS')} span={3}>
        {profile.address}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_ZIP')} span={3}>
        {profile.zipcode}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_CITY')} span={3}>
        {profile.city}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_BIRTH_DATE')} span={3}>
        {profile.birthday ? moment.utc(profile.birthday).format('DD/MM/YYYY') : null}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_COUNTRY')} span={3}>
        {countries.find((c) => c.code === profile.country)?.name}
      </Descriptions.Item>
      <Descriptions.Item label={t('FRONT_USER_LANGUAGE_SPOKEN')} span={3}>
        {profile.language && profile.language.map((l) => `${t(l)}, `)}
      </Descriptions.Item>
    </Descriptions>
  );
}

PatientInfos.propTypes = {
  patient: PropTypes.objectOf(PropTypes.any),
};

PatientInfos.defaultProps = {
  patient: null,
};

export default PatientInfos;
