import { FilePdfOutlined, FileProtectOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRoles } from '../../../redux/user/selectors';
import useStepActive from '../../../hooks/useDermatoStepActive';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import useHasPermission from '../../../hooks/useHasPermission';

const { TabPane } = Tabs;

function DermatoTabs() {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [medicalFile] = useMedicalFile();
  const roles = useRoles();
  const { isStep, beforeStep } = useStepActive();
  const hasPermission = useHasPermission();

  return (
    <Tabs activeKey={location.pathname} onChange={(key) => history.push(key)}>
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_PATIENT')}
          </>
        }
        key={`/medicals/${params.reference}/patient`}
      />
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_FILE')}
          </>
        }
        key={`/medicals/${params.reference}`}
      />
      <TabPane
        tab={
          <>
            <UserOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_HEALTH_FORM')}
          </>
        }
        key={`/medicals/${params.reference}/health`}
      />
      <TabPane
        tab={
          <>
            <FilePdfOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_DOCUMENTS')}
          </>
        }
        key={`/medicals/${params.reference}/documents`}
      />
    </Tabs>
  );
}

export default DermatoTabs;
