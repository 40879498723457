import { Button, Collapse, Form, Input, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrors from '../../components/form/FormErrors';
import SelectLanguages from '../../components/form/SelectLanguages';
import { useUser } from '../../redux/user/selectors';
import { updateUser, updateUserPassword } from '../../services/api';
import request from '../../services/request';

function UserAccount() {
  const { t } = useTranslation();
  const formRef = useRef();
  const [state, setState] = useState({ isFetching: false, err: null });
  const [user] = useUser();

  useEffect(() => {
    request('/me', 'GET');
  }, []);

  const update = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      await updateUser(user.id, values);
      setState({ isFetching: false, err: null });
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  const changePassword = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      await updateUserPassword(values);
      setState({ isFetching: false, err: null });
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  return (
    <div>
      <Title>{t('FRONT_SIDEBAR_ACCOUNT')}</Title>
      <Form
        layout="vertical"
        name="account"
        onFinish={update}
        initialValues={user}
        scrollToFirstError
        ref={formRef}
      >
        <Form.Item
          label={t('FRONT_USER_EMAIL')}
          name="email"
          rules={[{ type: 'email', required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <SelectLanguages />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>

      <Collapse style={{ marginBottom: 24 }}>
        <Collapse.Panel header={t('FRONT_USER_PASSWORD_CHANGE')}>
          <Form
            layout="vertical"
            name="password"
            onFinish={changePassword}
            scrollToFirstError
            ref={formRef}
          >
            <Form.Item
              label={t('FRONT_USER_PASSWORD_CURRENT')}
              name="current_password"
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t('FRONT_USER_PASSWORD_NEW')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
              ]}
              hasFeedback
              extra={t('FRONT_USER_PASSWORD_RULES')}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t('FRONT_USER_PASSWORD_CONFIRM')}
              name="password_confirmation"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('FRONT_USER_PASSWORD_NOTMATCH')));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={state.isFetching}>
                {t('FRONT_FORM_SAVE')}
              </Button>
            </Form.Item>
          </Form>
        </Collapse.Panel>
      </Collapse>

      {state.err && <FormErrors err={state.err} />}
    </div>
  );
}

export default UserAccount;
