import React from 'react';
import Home from '../pages/Home';
import Login from '../pages/authentication/Login';
import Register from '../pages/authentication/Register';
import Contact from '../pages/informations/Contact';
import PasswordCreate from '../pages/authentication/PasswordCreate';
import PasswordForgot from '../pages/authentication/PasswordForgot';
import PasswordReset from '../pages/authentication/PasswordReset';
import LegalTerms from '../pages/informations/LegalTerms';
import PrivacyPolicy from '../pages/informations/PrivacyPolicy';
import CornerstoneViewer from '../components/viewer/cornerstoneViewer';

const routes = [
  {
    component: <Home />,
    exact: true,
    path: '/',
  },
  {
    component: <Login />,
    exact: true,
    path: '/login',
  },
  {
    component: <PasswordForgot />,
    exact: true,
    path: '/password/forgot',
  },
  {
    component: <PasswordReset />,
    exact: true,
    path: '/password/reset',
  },
  {
    component: <PasswordCreate />,
    exact: true,
    path: '/password/create',
  },
  {
    component: <Register />,
    exact: true,
    path: '/register',
  },
  {
    component: <Contact />,
    exact: true,
    path: '/contact',
  },
  {
    component: <LegalTerms />,
    exact: true,
    path: '/terms',
  },
  {
    component: <PrivacyPolicy />,
    exact: true,
    path: '/policy',
  },
];

export default routes;
