import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Space, Divider, Alert } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import request from '../../services/request';

const styles = {
  form: { minWidth: 400 },
  divider: { margin: '16px 0' },
  background: {
    backgroundImage: 'url(/bg-connexion.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  lefttside: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
  },
};

function PasswordForgot() {
  const history = useHistory();
  const { t } = useTranslation();
  const [user] = useSelector((state) => state.user);
  const [currentState, setState] = useState({ isFetching: false, err: null, success: false });

  if (user) return <Redirect to="/" />;

  const resetPassword = async (values) => {
    setState({ isFetching: true, err: null, success: false });
    return request('/forgot-password ', 'POST', values)
      .then(() => setState({ isFetching: false, err: null, success: true }))
      .catch((err) => setState({ isFetching: false, err, success: false }));
  };

  return (
    <Row style={{ minHeight: 'calc(100vh - 64px)' }} justify="center" align="stretch">
      <Col span={12} style={styles.lefttside}>
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          <Title>{t('FRONT_LOGIN_FORGOT_PASSWORD')}</Title>
          <Form style={styles.form} onFinish={resetPassword}>
            <Form.Item
              name="email"
              rules={[
                { type: 'email' },
                { required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') },
              ]}
            >
              <Input size="large" prefix={<MailOutlined />} placeholder={t('FRONT_USER_EMAIL')} />
            </Form.Item>

            {currentState.success && (
              <Form.Item>
                <Alert message={t('FRONT_LOGIN_FORGOT_PASSWORD_SUCCESS')} type="success" />
              </Form.Item>
            )}
            <FormErrors err={currentState.err} />

            <Button
              style={styles.button}
              type="primary"
              htmlType="submit"
              loading={currentState.isFetching}
              block
            >
              {t('FRONT_LOGIN_FORGOT_PASSWORD_SEND')}
            </Button>

            <Divider style={styles.divider}>{t('FRONT_LOGIN_FORGOT_PASSWORD_BACK')}</Divider>

            <Button
              style={styles.button}
              type="primary"
              block
              onClick={() => history.push('/login')}
            >
              {t('FRONT_LOGIN_FORGOT_PASSWORD_CONNECT_BACK')}
            </Button>
          </Form>
        </Space>
      </Col>
      <Col span={12} style={styles.background} />
    </Row>
  );
}

export default PasswordForgot;
