import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DocumentCreate from '../../components/documents/Create';
import UserDocumentsList from '../../components/UserDocumentsList';
import WorkflowUser from '../../components/workflow/WorkflowUser';
import { createDocuments, fetchDocuments } from '../../redux/documents/actions';
import { useDocuments } from '../../redux/documents/selectors';
import { useFileTypes } from '../../redux/metadata/selectors';
import { useUser } from '../../redux/user/selectors';
import { useMedicals } from '../../redux/medicals/selectors';

import { fetchMedicalFiles } from '../../redux/medicals/actions';

function UserDocuments() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [documents, isFetching] = useDocuments();
  const [user] = useUser();
  const [medicalFiles] = useMedicals();
  const idFileTypes = useFileTypes('id');
  const idDocuments = useMemo(() => {
    const ids = documents?.filter((d) => idFileTypes.map((f) => f.id).includes(d.file_type_id));
    return ids;
  }, [documents, idFileTypes]);

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchMedicalFiles(user.id));
  }, [dispatch, user]);

  return (
    <>
      <WorkflowUser />
      <DocumentCreate
        types={idFileTypes}
        onSave={async (values) => {
          dispatch(createDocuments(null, user.id, values));
          if (medicalFiles.length > 0) history.push(`/medicals/${medicalFiles[0].reference}`);
          else history.push('/medicals');
        }}
        isUserDocuments
      />
      <UserDocumentsList documents={idDocuments} isFetching={isFetching} userId={user.id} />
    </>
  );
}

export default UserDocuments;
