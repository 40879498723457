import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import WorkflowUser from '../../components/workflow/WorkflowUser';
import request, { isOnAxaWebsite } from '../../services/request';

const { Title, Paragraph, Text, Link } = Typography;

function Dashboard() {
  useEffect(() => {
    request('/me', 'GET');
  }, []);

  return (
    <>
      <div className="dashboard">
        <div style={{ marginTop: 20 }}>
          <WorkflowUser />
        </div>
        {isOnAxaWebsite() ? (
          <Typography className="dashboard__content">
            <p>
              Comme il vous a été notifié par votre employeur, nous avons le plaisir de vous donner
              accès au service de France Surgery qui va vous permettre d’effectuer un bilan de
              santé.
            </p>
            <br />
            <p>
              Vous trouverez sur votre espace un dossier sécurisé afin de préparer votre bilan de
              santé et de vous mettre en relation avec un de nos centres de bilan partenaire. Vous
              aurez la possibilité de compléter le questionnaire de santé qui permettra de préparer
              au mieux votre bilan, si vous ne souhaitez pas remplir ce questionnaire au préalable,
              vous aurez la possibilité de le remplir directement In Situ avec le professionnel de
              santé qui vous accueillera le jour de votre bilan. Si vous souhaitez transmettre des
              analyses ou documents médicaux spécifiques à votre état de santé, vous avez la
              possibilité de le faire sur votre espace sécurisé dans la rubrique « documents » et le
              médecin en charge de votre bilan en sera automatiquement informé.
            </p>
            <br />
            <p>
              Nous restons à votre entière disposition pour vous accompagner tout au long de votre
              parcours de soins. Si vous avez des questions, n’hésitez pas à nous contacter via la
              messagerie sécurisée de votre espace personnel ou par téléphone au +33 (0)9 53 02 03
              09.
            </p>
            <br />
            <p>
              Bien cordialement,
              <br />
              L’équipe de France Surgery
            </p>
          </Typography>
        ) : (
          <Typography className="dashboard__content">
            <Title style={{ fontWeight: 'normal' }}>
              <Trans i18nKey="FRONT_HOME_TITLE" components={{ sup: <sup /> }} />
            </Title>
            <Paragraph>
              <Trans i18nKey="FRONT_DASHBOARD_WELCOME" components={{ sup: <sup /> }} />
            </Paragraph>
            <Paragraph>
              <Trans i18nKey="FRONT_DASHBOARD_LIST">
                Voici les étapes :
                <ol className="list__numbered">
                  <li> Je finis mon inscription en complétant mon profil</li>
                  <li> Je crée mon dossier médical</li>
                  <li> Je remplis mon questionnaire de santé</li>
                  <li> J’ajoute mes documents médicaux</li>
                  <li> J’attends d’être contacté(e) par le Bureau International Elsan</li>
                </ol>
              </Trans>
            </Paragraph>
            <Paragraph>
              <Trans i18nKey="FRONT_HOME_TEAM" />
            </Paragraph>
          </Typography>
        )}
      </div>
    </>
  );
}

export default Dashboard;
