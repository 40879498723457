import { CloseCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { Header as ANTDHeader } from 'antd/lib/layout/layout';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../Flex';
import i18n from '../../services/i18n';
import languages from '../../services/languages';
import { signOut } from '../../redux/user/actions';
import { updateUser } from '../../services/api';
import { isPatient } from '../../services/rights';
import { useRoles } from '../../redux/user/selectors';
import { fetchMetadata } from '../../redux/metadata/actions';
import { fetchWorlflowUser } from '../../redux/workflowUser/actions';
import { isOnAxaWebsite, url } from '../../services/request';

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [user] = useSelector((state) => state.user);
  const roles = useRoles();

  const updateLanguage = async (value) => {
    try {
      if (user) {
        await updateUser(user.id, { language: value });
        dispatch(fetchWorlflowUser(user.id));
        dispatch(fetchMetadata());
      }
      await i18n.changeLanguage(value);
    } catch (err) {
      window.location.reload(false);
    }
  };

  return (
    <ANTDHeader className="header">
      <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname, i18n.language]}>
        <Menu.Item className="header__logo__wrapper" onClick={() => history.push('/')}>
          {user && user.company.logo ? (
            <img
              className="header__logo"
              alt={`Logo ${user.company.name}`}
              src={`data:image/png;base64,${user.company.logo}`}
            />
          ) : (
            <img className="header__logo" src="/logo.png" alt="Logo France Surgery" />
          )}
        </Menu.Item>
        <Menu.Item key="/" onClick={() => history.push('/')}>
          {t('FRONT_HEADER_HOME')}
        </Menu.Item>
        <Menu.Item key="/contact" onClick={() => history.push('/contact')}>
          {t('FRONT_HEADER_CONTACT')}
        </Menu.Item>
        {user == null && (
          <Menu.Item key="/login" onClick={() => history.push('/login')}>
            {t('FRONT_HEADER_LOGIN')}
          </Menu.Item>
        )}
        {user && isPatient(roles) && (
          <Menu.Item key="/dashboard" onClick={() => history.push('/dashboard')}>
            {t('FRONT_HEADER_DASHBOARD')}
          </Menu.Item>
        )}

        {user && !isPatient(roles) && (
          <Menu.Item key="/medicals" onClick={() => history.push('/medicals')}>
            {t('FRONT_SIDEBAR_MEDICALS')}
          </Menu.Item>
        )}
      </Menu>

      <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname, i18n.language]}>
        {user && (
          <>
            <Menu.Item key="/profile" onClick={() => history.push('/profile')}>
              <Flex>
                <Avatar>{`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}</Avatar>
                <div style={{ marginLeft: 10 }}>{`${user.firstname} ${user.lastname}`}</div>
              </Flex>
            </Menu.Item>
            <Menu.Item
              key="/account"
              icon={<SettingOutlined />}
              onClick={() => history.push('/account')}
            />
            <Menu.Item
              icon={<CloseCircleOutlined />}
              onClick={() => {
                if (isOnAxaWebsite()) window.location.href = `${url}/auth0/logout`;
                else dispatch(signOut());
              }}
            />
          </>
        )}
        <SubMenu title={i18n.language.toUpperCase()}>
          {languages
            .filter((l) => l.value === 'en' || l.value === 'fr')
            .map((l) => (
              <Menu.Item key={l.value} onClick={() => updateLanguage(l.value)}>
                {`${t(l.label)} (${t(l.code)})`}
              </Menu.Item>
            ))}
        </SubMenu>
      </Menu>
    </ANTDHeader>
  );
}

export default Header;
