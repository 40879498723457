import { EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { useCheckupType, useMedicaleFileType, usePathology } from '../../redux/metadata/selectors';
import { fetchSearchMedicalFiles } from '../../services/api';

const styles = { icon: { fontSize: 18 } };

const renderTag = (value) => {
  if (value === 0) return <Tag color="green">Close</Tag>;
  if (value === 1) return <Tag color="orange">Waiting</Tag>;
  return <Tag color="red">Open</Tag>;
};

function MedicalFileSearch() {
  const { t } = useTranslation();
  const location = useLocation();
  const [[state, isFetching], setState] = useState([null, false]);
  const search = location?.state;
  const [values, setValues] = useState(search || {});
  const hasPermission = useHasPermission();
  const pathologies = usePathology();
  const type = useMedicaleFileType();
  const checkupTypes = useCheckupType();

  const fetchData = (page) => {
    setState([null, true]);
    fetchSearchMedicalFiles(values, page)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    if (search) {
      setState([null, true]);
      fetchSearchMedicalFiles(search)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    }
  }, [search]);

  const columns = [
    {
      title: '',
      dataIndex: ['step', 'commitment'],
      render: (i) => renderTag(i),
    },
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      render: (value) => <Link to={`/medicals/${value}`}>{value}</Link>,
    },
    {
      title: t('FRONT_MEDICAL_FILE_TYPE'),
      dataIndex: 'type',
      width: 250,
      render: (i) => type[i],
      sorter: true,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i, record) =>
            record.pathology !== null
              ? pathologies.find((p) => p.id === record.pathology).name
              : checkupTypes.find((p) => p.id === record.checkup_type).name,
          sorter: true,
        }
      : {},
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: ['user', 'firstname'] },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: ['user', 'lastname'] },
    { title: t('FRONT_MEDICAL_FILE_STATUS'), dataIndex: ['step', 'description'] },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Link to={`/medicals/${record.reference}`}>
          <EyeOutlined style={styles.icon} />
        </Link>
      ),
    },
  ];

  return (
    <>
      <Form
        initialValues={search || {}}
        name="search_medical_file"
        layout="vertical"
        onFinish={() => {
          fetchData();
        }}
        onValuesChange={(a, b) => setValues(b)}
        scrollToFirstError
      >
        <Form.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} name="reference">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_FIRST_NAME')} name="firstname">
          <Input />
        </Form.Item>

        <Form.Item label={t('FRONT_USER_LAST_NAME')} name="lastname">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SEARCH')}
          </Button>
        </Form.Item>
      </Form>

      {state && (
        <TableCustom
          state={state}
          isFetching={isFetching}
          columns={columns}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default MedicalFileSearch;
