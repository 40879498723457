import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentCreate from '../../../components/documents/Create';
import { createDocuments, fetchDocuments, removeDocument } from '../../../redux/documents/actions';
import { useDocuments } from '../../../redux/documents/selectors';
import Comments from '../../../components/medicalFile/Comments';
import DocumentsList from '../../../components/documents/List';
import { useMedicalFileFileTypes, usePatient } from '../../../redux/medicalFile/selectors';
import { fetchStep } from '../../../redux/step/actions';
import { isClinicCoordinator, isExpertDoctor, isAdmin } from '../../../services/rights';
import { useRoles } from '../../../redux/user/selectors';
import { useFileTypes } from '../../../redux/metadata/selectors';

function Management({ medicalFile }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const roles = useRoles();
  const [currentKey, setCurrentKey] = useState(location.state?.defaultTab);

  const [patient] = usePatient();
  const [documents, isFetching] = useDocuments();

  const managementFileTypes = useMedicalFileFileTypes('management');
  const administrativeFileTypes = useMedicalFileFileTypes('administrative');
  const idFileTypes = useFileTypes('id');

  const [managements, administratives] = useMemo(() => {
    const a = documents?.filter((d) =>
      administrativeFileTypes.map((f) => f.id).includes(d.file_type_id)
    );
    const i = documents?.filter((d) => idFileTypes.map((f) => f.id).includes(d.file_type_id));
    a.push(...i);
    const m = documents?.filter((d) =>
      managementFileTypes.map((f) => f.id).includes(d.file_type_id)
    );
    return [m, a];
  }, [documents, managementFileTypes, administrativeFileTypes, idFileTypes]);

  useEffect(() => {
    if (medicalFile) {
      dispatch(fetchDocuments(medicalFile));
    } else {
      dispatch(fetchDocuments());
    }
  }, [medicalFile, dispatch]);

  return (
    <>
      <Collapse
        accordion
        bordered={false}
        activeKey={currentKey}
        onChange={(val) => setCurrentKey(val)}
      >
        <Collapse.Panel header={t('FRONT_DOCUMENT_UPLOAD')} key={1}>
          <DocumentCreate
            types={[...administrativeFileTypes, ...managementFileTypes]}
            onSave={async (values) => {
              await dispatch(createDocuments(medicalFile, null, values));
              if (managementFileTypes.find((f) => f.id === values.file_type_id)) setCurrentKey(2);
              else setCurrentKey(3);
            }}
          />
        </Collapse.Panel>
        <Collapse.Panel header={t('FRONT_DOCUMENT_MANAGEMENT')} key={2}>
          <DocumentsList
            documents={managements}
            isFetching={isFetching}
            onRemove={(index) => dispatch(removeDocument(medicalFile.reference, null, index))}
          />
        </Collapse.Panel>
        <Collapse.Panel header={t('FRONT_DOCUMENT_ADMINISTRATIVE')} key={3}>
          <DocumentsList
            documents={administratives}
            medicalFile={medicalFile}
            onRemove={(index) => dispatch(removeDocument(null, medicalFile.user.id, index))}
          />
        </Collapse.Panel>
        {(isExpertDoctor(roles) || isClinicCoordinator(roles) || isAdmin(roles)) && (
          <Collapse.Panel header={t('FRONT_MEDICAL_FILE_COMMENTS_MANAGEMENT')} key={4}>
            <Comments url={`/medicalfiles/${medicalFile.reference}/managementcomments`} />
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  );
}

Management.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Management;
