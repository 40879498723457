import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import Loading from '../../components/layouts/Loading';
import { signIn, signOut } from '../../redux/user/actions';
import { isOnAxaWebsite, url } from '../../services/request';

function Logout() {
  const dispatch = useDispatch();
  const [user] = useSelector((state) => state.user);

  useEffect(() => {
    if (isOnAxaWebsite()) window.location.href = `${url}/auth0/logout`;
    else dispatch(signOut());
  });

  if (user) return <Loading />;

  return <Redirect to="/login" />;
}

export default Logout;
