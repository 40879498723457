/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MedicalFileDocuments from '../../../pages/medicalFile/SMO/Documents';
import Satisfaction from '../../../pages/medicalFile/Satisfaction';
import Contact from '../../../pages/medicalFile/SMO/Contact';
import Patient from '../../../pages/medicalFile/Patient';
import MedicalFileDetails from '../../../pages/medicalFile/Details';
import FinalSMO from '../../../pages/medicalFile/SMO/FinalSMO';
import SMO from '../../../pages/medicalFile/SMO/SMO';
import HealthForm from '../../medicalFile/HealthForm';

function SMORouter({ medicalFile }) {
  return (
    <Switch>
      <Route path="/medicals/:reference" exact>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/patient" exact>
        <Patient medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/health" exact>
        <HealthForm medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/smo/final" exact>
        <FinalSMO reference={medicalFile.reference} />
      </Route>
      <Route path="/medicals/:reference/smo">
        <SMO reference={medicalFile.reference} />
      </Route>
      <Route path="/medicals/:reference/documents" exact>
        <MedicalFileDocuments medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/contact" exact>
        <Contact medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/satisfaction" exact>
        <Satisfaction medicalFile={medicalFile} />
      </Route>
      <Route>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
    </Switch>
  );
}

export default SMORouter;
