import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import WorkflowUser from '../components/workflow/WorkflowUser';
import HomeImage from '../assets/images/Home.png';
import { isOnAxaWebsite } from '../services/request';
import { useUser } from '../redux/user/selectors';

const { Title, Paragraph, Text, Link } = Typography;

function Home() {
  const { t } = useTranslation();
  const [user] = useUser();

  return (
    <section className="home">
      <Row align="middle">
        <Col sm={24} md={12}>
          {user && <WorkflowUser />}
          {isOnAxaWebsite() ? (
            <Typography className="home__description">
              <p>
                Comme il vous a été notifié par votre employeur, nous avons le plaisir de vous
                donner accès au service de France Surgery qui va vous permettre d’effectuer un bilan
                de santé.
              </p>
              <br />
              <p>
                Vous trouverez sur votre espace un dossier sécurisé afin de préparer votre bilan de
                santé et de vous mettre en relation avec un de nos centres de bilan partenaire. Afin
                de respecter les régles d’identito-vigilances, votre rendez-vous de bilan ne pourra
                être confirmé que si vous avez téléchargé votre carte nationale d’identité et rempli
                votre questionnaire santé sur votre espace sécurisé. Nous vous remercions pour votre
                compréhension.
              </p>
              <br />
              <p>
                Nous restons à votre entière disposition pour vous accompagner tout au long de votre
                parcours de soins. Si vous avez des questions, n’hésitez pas à nous contacter via la
                messagerie sécurisée de votre espace personnel ou par téléphone au +33 (0)9 53 02 03
                09.
              </p>
              <br />
              <p>
                Bien cordialement,
                <br />
                L’équipe de France Surgery
              </p>
            </Typography>
          ) : (
            <Typography className="home__description">
              <Title style={{ fontWeight: 'normal' }}>
                <Trans i18nKey="FRONT_HOME_TITLE" components={{ sup: <sup /> }} />
              </Title>
              <Paragraph>
                <Trans i18nKey="FRONT_HOME_WELCOME" />
              </Paragraph>
              <Paragraph>
                <Trans i18nKey="FRONT_HOME_DESCRIPTION">
                  We provide:
                  <ol className="list__numbered">
                    <li>
                      {' '}
                      Secure online medical records. Your health documents are available on your
                      mobile phone and always with you, so that you can share them with your
                      physicians whenever you need it.
                    </li>
                    <li>
                      {' '}
                      Secure teleconsultations with the best specialists, from anywhere in the
                      world.
                    </li>
                    <li>
                      {' '}
                      Organization of healthcare abroad by our multilingual patient call center.
                    </li>
                    <li>
                      {' '}
                      Control quotes / billing & payment centralization, thanks to our certified
                      case managers.
                    </li>
                  </ol>
                </Trans>
              </Paragraph>
              <Paragraph strong>
                <Trans i18nKey="FRONT_HOME_TEAM" />
              </Paragraph>
            </Typography>
          )}
        </Col>
        <Col sm={24} md={12} className="home__image">
          <img src={HomeImage} alt="Landing" />
        </Col>
      </Row>
    </section>
  );
}

export default Home;
