import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Collapse } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentCreate from '../../../components/documents/Create';
import DocumentsList from '../../../components/documents/List';
import { createDocuments, fetchDocuments, removeDocument } from '../../../redux/documents/actions';
import { useDocuments } from '../../../redux/documents/selectors';
import DocumentInfo from '../../../components/documents/Info';
import { fetchStep } from '../../../redux/step/actions';
import { useMedicalFileFileTypes } from '../../../redux/medicalFile/selectors';

function MedicalFileDocuments({ medicalFile }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [currentKey, setCurrentKey] = useState(location.state?.defaultTab);
  const [documents, isFetching] = useDocuments();
  const medicalFileTypes = useMedicalFileFileTypes('medical');
  const prescriptionFileTypes = useMedicalFileFileTypes('prescription');

  const [prescriptions, medicals] = useMemo(() => {
    const p = documents?.filter((d) =>
      prescriptionFileTypes.map((f) => f.id).includes(d.file_type_id)
    );
    const m = documents?.filter((d) => medicalFileTypes.map((f) => f.id).includes(d.file_type_id));
    return [p, m];
  }, [documents, medicalFileTypes, prescriptionFileTypes]);

  useEffect(() => {
    dispatch(fetchDocuments(medicalFile));
  }, [medicalFile, dispatch]);

  return (
    <>
      {documents.length === 0 && <DocumentInfo type={medicalFile.type} />}
      <Collapse
        accordion
        bordered={false}
        activeKey={currentKey}
        onChange={(val) => setCurrentKey(val)}
      >
        <Collapse.Panel header={t('FRONT_DOCUMENT_UPLOAD')} key={1}>
          <DocumentCreate
            types={[...prescriptionFileTypes, ...medicalFileTypes]}
            onSave={async (values) => {
              dispatch(createDocuments(medicalFile, null, values));
              dispatch(fetchStep(medicalFile.reference));
              if (medicalFileTypes.find((f) => f.id === values.file_type_id)) setCurrentKey(3);
              else setCurrentKey(2);
            }}
            zipTutorial
          />
        </Collapse.Panel>
        <Collapse.Panel header={t('FRONT_DOCUMENT_PRESCRIPTIONS')} key={2}>
          <DocumentsList
            documents={prescriptions}
            isFetching={isFetching}
            onRemove={(index) => dispatch(removeDocument(medicalFile.reference, null, index))}
          />
        </Collapse.Panel>
        <Collapse.Panel header={t('FRONT_DOCUMENT_MEDICALS')} key={3}>
          <DocumentsList
            documents={medicals}
            isFetching={isFetching}
            onRemove={(index) => dispatch(removeDocument(medicalFile.reference, null, index))}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
}

MedicalFileDocuments.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MedicalFileDocuments;
