/* eslint-disable react/prop-types */
import { Descriptions } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function FormInfos({ questions, answers, type = 'health' }) {
  const { t } = useTranslation();
  return (
    <Descriptions
      className="form__descriptions"
      bordered
      column={1}
      style={{ marginBottom: 24 }}
      labelStyle={{ backgroundColor: 'white' }}
    >
      {(questions.groupBy || questions).map((q) => {
        let { answer } = answers.find((a) => a[`${type}_question_id`] === q.id) || {};
        if (!answer) return null;
        if (q.answers?.length > 0) {
          const answerSplited = answer.split(','); // prevent multiple value
          answerSplited.push(null); // trick to reduce even if array.length = 1
          const childAnswer = answerSplited.reduce((acc = '', currentValue, index) => {
            if (currentValue === null) return acc;
            // eslint-disable-next-line eqeqeq
            const { label: found } = q.answers.find((a) => a.value == currentValue);
            const nextValue = answerSplited.length > 2 && index > 0 ? `, ${found}` : found;
            return acc.concat(nextValue);
          }, '');
          answer = childAnswer;
        }
        if (q.type === 'boolean') {
          // eslint-disable-next-line no-nested-ternary
          answer = answer
            ? answer === '0'
              ? t('FRONT_COMMON_FALSE')
              : t('FRONT_COMMON_TRUE')
            : '';
        }

        return (
          <Descriptions.Item key={q.id} label={q.question}>
            {answer}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
}

export default FormInfos;
