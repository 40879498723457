import { SET_MEDICAL_FILES, MEDICAL_FILES_IS_FETCHING } from './actions';

const medicals = (state = [[], false], action) => {
  switch (action.type) {
    case SET_MEDICAL_FILES:
      return [action.medicalFiles, false];
    case MEDICAL_FILES_IS_FETCHING:
      return [state[0], true];
    default:
      return state;
  }
};

export default medicals;
