import { useStep } from '../redux/step/selectors';

const useT4TStepActive = () => {
  const [step] = useStep();

  const steps = [
    'patient.stepfillmedicalform',
    'patient.stepuploadmedicalfiles',
    'admin.stepwaitingforsmopayment',
    'admin.stepassigndoctor',
    'doctor.stepvalidatemedicalfile',
    'patient.stepfilltravelform',
    'system.stepcommercialproposition',
    'patient.stepvalidatecommercialproposition',
    'system.stepwaitingforadvancepayment',
    'system.stepwaitingfortraveldata',
    'system.stepwaitingfortreatmentcourse',
    'system.stepwaitingforconsent',
    'patient.stepvalidateconsent',
    'system.steptravelfilecomplete',
    'system.stepwaitingforpayment',
    'system.stepwaitingforintervention',
    'system.steptravelinprogress',
    'patient.stepfillsatisfactionform',
    'patient.viewcheckupresults',
    'system.fileclosed',
  ];

  const stepActive = steps.findIndex((s) => s === step?.reference);

  const beforeStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive < findIndex;
  };

  const isStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive === findIndex;
  };

  const afterStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive > findIndex;
  };

  return { beforeStep, isStep, afterStep };
};

export default useT4TStepActive;
