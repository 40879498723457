/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Tag, Form, Space, message, Switch } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Flex from '../../components/Flex';
import TableCustom from '../../components/Table';
import WorkflowUser from '../../components/workflow/WorkflowUser';
import useHasPermission from '../../hooks/useHasPermission';
import { useCheckupType, useMedicaleFileType, usePathology } from '../../redux/metadata/selectors';
import { useRoles } from '../../redux/user/selectors';
import { deleteMedicalfile, fetchMedicalFiles } from '../../services/api';
import { isPatient } from '../../services/rights';

const styles = { icon: { fontSize: 18, cursor: 'pointer' } };

function MedicalFilesList() {
  const history = useHistory();
  const { t } = useTranslation();
  const pathologies = usePathology();
  const type = useMedicaleFileType();
  const checkupTypes = useCheckupType();

  const hasPermission = useHasPermission();
  const roles = useRoles();
  const [[state, isFetching], setState] = useState([null, true]);
  const [all, setAll] = useState(false);

  const fetchData = async (page, order) => {
    setState([state, true]);
    fetchMedicalFiles(page, order, all)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    fetchData();
  }, [all]);

  const removeMedicalfile = async (reference) => {
    try {
      setState(([c]) => [c, true]);
      await deleteMedicalfile(reference);
      await fetchData(state.meta.current_page);
      message.success(t('FRONT_NOTIFICATION_DELETE_SUCCESS'));
    } catch (err) {
      setState(([c]) => [c, false]);
      message.error(t('FRONT_NOTIFICATION_DELETE_FAILED'));
    }
  };

  const columns = [
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      fixed: 'left',
      width: 100,
      render: (r) => <Link to={`/medicals/${r}`}>{r}</Link>,
      sorter: true,
    },
    {
      title: t('FRONT_MEDICAL_FILE_TYPE'),
      dataIndex: 'type',
      responsive: ['md'],
      render: (i) => type[i],
      sorter: true,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i, record) =>
            record.pathology !== null
              ? pathologies.find((p) => p.id === record.pathology).name
              : checkupTypes.find((p) => p.id === record.checkup_type).name,
          sorter: true,
          responsive: ['md'],
        }
      : {},
    !isPatient(roles)
      ? {
          title: t('FRONT_USER_FIRST_NAME'),
          dataIndex: ['user', 'firstname'],
          width: 150,
          sorter: true,
        }
      : {},
    !isPatient(roles)
      ? {
          title: t('FRONT_USER_LAST_NAME'),
          dataIndex: ['user', 'lastname'],
          width: 150,
          sorter: true,
        }
      : {},
    {
      title: t('FRONT_MEDICALE_FILE_START_CHECKUP'),
      dataIndex: ['travel_date'],
      render: (r) => r && new Date(r).toLocaleDateString(),
      width: 150,
    },
    {
      title: t('FRONT_MEDICAL_FILE_STATUS'),
      dataIndex: ['step', 'description'],
      sorter: true,
      responsive: ['lg'],
    },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
      sorter: true,
      responsive: ['md'],
    },
    {
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, item) => (
        <Space size="large">
          <Link to={`/medicals/${item.reference}`}>
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {hasPermission('delete health files') && (
            <DeleteOutlined
              onClick={() => removeMedicalfile(item.reference)}
              style={{ ...styles.icon, color: 'red' }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <WorkflowUser />
      <Title>{t('FRONT_MEDICAL_FILE_LABELS')}</Title>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Form
          layout="inline"
          style={{ marginBottom: 10 }}
          onFinish={(v) => history.push('/medicals/search', v)}
        >
          <Form.Item label={t('FRONT_USER_LAST_NAME')} name="lastname">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('FRONT_FORM_SEARCH')}
            </Button>
          </Form.Item>
        </Form>
        <FormItem style={{ display: 'inline-block', marginRight: 15 }}>
          <Switch
            defaultChecked={false}
            checkedChildren={t('FRONT_MEDICAL_FILE_ALL')}
            unCheckedChildren={t('FRONT_MEDICAL_FILE_INPROGRESS')}
            onChange={(val) => setAll(val)}
          />
        </FormItem>
      </Flex>

      <TableCustom
        state={state}
        columns={columns}
        isFetching={isFetching}
        fetchData={fetchData}
        xScroll={1500}
      />
    </>
  );
}

export default MedicalFilesList;
