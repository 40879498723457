import React from 'react';
import UserProfile from '../pages/user/Profile';
import UserAccount from '../pages/user/Account';
import MyDocuments from '../pages/user/Documents';
import ListUsers from '../pages/users/List';
import CreateUser from '../pages/users/Create';
import MedicalFiles from '../pages/users/MedicalFiles';
import EntitiesList from '../pages/entities/List';
import CreateEntity from '../pages/entities/Create';
import MedicalFile from '../pages/medicalFile';
import MedicalFilesList from '../pages/medicalFiles/List';
import MedicalFileCreate from '../pages/medicalFiles/Create';
import FileTraduction from '../pages/FileTraduction';
import Dashboard from '../pages/user/Dashboard';
import Logout from '../pages/authentication/Logout';
import Viewer from '../pages/viewer/Viewer';
import MedicalFileSearch from '../pages/medicalFiles/Search';
import EmailCheck from '../pages/authentication/EmailCheck';
import PatientsList from '../pages/patients/List';
import PatientCreate from '../pages/patients/Create';

const routes = [
  {
    component: <Dashboard />,
    path: '/dashboard',
  },
  {
    component: <UserProfile />,
    path: '/profile',
  },
  {
    component: <UserAccount />,
    path: '/account',
  },
  {
    component: <MyDocuments />,
    path: '/documents',
  },
  {
    component: <Logout />,
    path: '/logout',
  },
  {
    component: <ListUsers />,
    path: '/users',
    exact: true,
  },
  {
    component: <CreateUser />,
    path: '/users/add',
  },
  {
    component: <MedicalFiles />,
    path: '/users/:userId/medicals',
  },
  {
    path: '/entities',
    component: <EntitiesList />,
    exact: true,
  },
  {
    component: <CreateEntity />,
    path: '/entities/add',
  },
  {
    component: <MedicalFilesList />,
    path: '/medicals',
    exact: true,
  },
  {
    component: <MedicalFileCreate />,
    path: '/medicals/add',
  },
  {
    component: <MedicalFileSearch />,
    path: '/medicals/search',
  },
  {
    component: <PatientsList />,
    path: '/patients',
    exact: true,
  },
  {
    component: <PatientCreate />,
    path: '/patients/add',
  },
  {
    component: <Viewer />,
    path: '/viewer',
  },
  {
    component: <MedicalFile />,
    path: '/medicals/:reference',
  },
  {
    component: <FileTraduction />,
    path: '/translation',
  },
  {
    component: <EmailCheck />,
    path: '/email/verify',
  },
];

export default routes;
