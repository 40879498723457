import { Button, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { isOnAxaWebsite, url } from '../../services/request';
import Flex from '../Flex';
import Header from './Header';
import Sider from './Sider';

function LayoutPrivate({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [user] = useSelector((state) => state.user);

  if (!user)
    if (isOnAxaWebsite())
      window.location.href = `${url}/auth0/login${
        location.pathname ? `?redirectTo=${location.pathname}` : ''
      }`;
    else
      return <Redirect to={{ pathname: '/login', state: { prevLocation: location.pathname } }} />;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout className="content" hasSider>
        <Sider />
        <Layout>
          <Content style={{ padding: 24 }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

LayoutPrivate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutPrivate;
