/* eslint-disable react/prop-types */
import { Form, Skeleton, Button, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FormDynamic from '../../components/form/FormDynamic';
import FormErrors from '../../components/form/FormErrors';
import FormInfos from '../../components/infos/FormInfos';
import { nextStep } from '../../redux/step/actions';
import {
  createSatisfactionAnswers,
  fetchSatisfactionQuestions,
  fetchSatisfactionAnswers,
} from '../../services/api';

function Satisfaction({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState({ questions: [], responses: [], isFetching: true });
  const [[isSaving, saveErr], setIsSaving] = useState([false, null]);

  const { reference, id } = medicalFile;

  const fetchForm = useCallback(async () => {
    try {
      const questions = await fetchSatisfactionQuestions(reference);
      const answers = await fetchSatisfactionAnswers(reference);
      setState({ questions, responses: answers, isFetching: false });
    } catch {
      setState({ questions: [], responses: [], isFetching: false });
    }
  }, [reference]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  const save = async (values) => {
    setIsSaving([true, null]);
    const arr = Object.entries(values)
      .filter(([, v]) => v)
      .map(([k, v]) => [k, v.toString()]);
    const a = arr.map(([key, answer]) => ({ satisfaction_question_id: Number(key), answer }));

    try {
      await createSatisfactionAnswers(reference, {
        medical_file_id: id,
        answers: a,
      });
      setIsSaving([false, null]);
      dispatch(nextStep(reference));
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
    } catch (err) {
      setIsSaving([false, err]);
      message.error(t('FRONT_NOTIFICATION_SAVE_FAILED'));
    }
  };

  if (state.isFetching) return <Skeleton />;
  if (!id || !reference) return null;

  return (
    <div>
      <Title>{t('FRONT_SATISFACTION_FORM_LABEL')}</Title>
      {state.responses.length === 0 ? (
        <Form layout="vertical" name="satisfaction" onFinish={save}>
          <FormDynamic
            questions={state.questions}
            responses={state.responses}
            isFetching={state.isFetching}
            type="satisfaction"
          />

          <FormErrors err={saveErr} />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <FormInfos questions={state.questions} answers={state.responses} type="satisfaction" />
      )}
    </div>
  );
}

export default Satisfaction;
