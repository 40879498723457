import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import regions from '../../services/regions';

function SelectRegion() {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('FRONT_MEDICAL_FILE_REGION')}
      name="region"
      rules={[
        {
          required: true,
          message: `${t('FRONT_MEDICAL_FILE_REGION')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
    >
      <Select placeholder={t('FRONT_COMMON_SELECT')}>
        {regions.map((p, i) => (
          <Select.Option key={p} value={p}>
            {p}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectRegion;
