import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Space, Divider, Alert } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import request from '../../services/request';

const styles = {
  form: { minWidth: 400 },
  divider: { margin: '16px 0' },
  background: {
    backgroundImage: 'url(/bg-connexion.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  lefttside: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
  },
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordCreate() {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email');
  const [user] = useSelector((state) => state.user);
  const [currentState, setState] = useState({ isFetching: false, err: null, success: false });

  if (user) return <Redirect to="/" />;

  const resetPassword = async (values) => {
    setState({ isFetching: true, err: null, success: false });
    return request('/create-password ', 'POST', { ...values, email, token })
      .then(() => setState({ isFetching: false, err: null, success: true }))
      .catch((err) => setState({ isFetching: false, err, success: false }));
  };

  return (
    <Row style={{ minHeight: 'calc(100vh - 64px)' }} justify="center" align="stretch">
      <Col span={12} style={styles.lefttside}>
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          <Title>{t('FRONT_USER_PASSWORD_RESET')}</Title>
          <Form style={styles.form} layout="vertical" onFinish={resetPassword}>
            <Form.Item
              name="password"
              label={t('FRONT_USER_PASSWORD_NEW')}
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
              ]}
              hasFeedback
              extra={t('FRONT_USER_PASSWORD_RULES')}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="password_confirmation"
              label={t('FRONT_USER_PASSWORD_CONFIRM')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('FRONT_USER_PASSWORD_NOTMATCH')));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <FormErrors err={currentState.err} />

            {currentState.success ? (
              <>
                <Form.Item>
                  <Alert message={t('FRONT_RESET_PASSWORD_SUCCESS')} type="success" />
                </Form.Item>
                <Button
                  style={styles.button}
                  type="primary"
                  block
                  onClick={() => history.push('/login')}
                >
                  {t('FRONT_REGISTER_LOGIN')}
                </Button>
              </>
            ) : (
              <Button style={styles.button} type="primary" block htmlType="submit">
                {t('FRONT_FORM_SEND')}
              </Button>
            )}
          </Form>
        </Space>
      </Col>
      <Col span={12} style={styles.background} />
    </Row>
  );
}

export default PasswordCreate;
