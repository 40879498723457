import { Button, Progress, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Flex from '../../components/Flex';
import CornerstoneViewer from '../../components/viewer/cornerstoneViewer';
import { url as baseUrl, fetchFile, fetchJson, getMimeType } from '../../services/request';
import './viewer.css';

const styles = {
  back: { marginBottom: 10 },
  flex: { overflow: 'auto', justifyContent: 'flex-start' },
  image: { height: 'calc(100vh - 200px)' },
};
const b64toBlob = (base64, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

function Viewer() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const ref = useRef();
  const { current: loadedFiles } = useRef([]);
  const [isFetching, setIsFetching] = useState(true);
  const loaded = useRef(0);

  const { url: path, length, files, reference } = state;

  const downloadFile = useCallback(
    async (downloadUrl) => {
      try {
        const data = await fetchFile(downloadUrl)
          .then((res) => {
            const reader = res.body.getReader();
            return new ReadableStream({
              start(controller) {
                function pump() {
                  return reader.read().then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }
                    loaded.current += value.length;
                    controller.enqueue(value);
                    pump();
                  });
                }
                return pump();
              },
            });
          })
          .then((stream) => new Response(stream))
          .then(async (response) => {
            const json = await response.json().catch(() => null);
            if (!response.ok) throw json;
            return json;
          });
        if (Array.isArray(data)) {
          const promiseRes = await Promise.all(
            data.map(async (f) => {
              const blob = await b64toBlob(f.file, f.mime);
              const fileObjectURL = URL.createObjectURL(blob);
              f.url = fileObjectURL;
              return f;
            })
          );
          return promiseRes;
        }
        if (!data.message) {
          const blob = await b64toBlob(data.file, data.mime);
          const fileObjectURL = URL.createObjectURL(blob);
          data.url = fileObjectURL;
          return data;
        }
        return null;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [loaded]
  );

  const fetchFileRefs = useCallback(async (fetchUrl, medicalFileIndex) => {
    try {
      const dicomRefsPath = `${fetchUrl}/${medicalFileIndex}/dicom`;
      const fetchRes = await fetchJson(dicomRefsPath);
      if (!!fetchRes && !!fetchRes.data) {
        const refs = fetchRes.data.map(({ index, name }) => {
          if (/(.jpg|.png|.jpeg)/i.test(name)) {
            return `${baseUrl}${fetchUrl}/${medicalFileIndex}/dicom/${index}`;
          }
          return `wadouri:${baseUrl}${fetchUrl}/${medicalFileIndex}/dicom/${index}`;
        });
        // will be read to define the DICOM loader to use
        return refs;
      }
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }, []);

  const fetchAllFiles = useCallback(async () => {
    const res = await Promise.all(
      files.map(async (f) => {
        const fetchUrl = f.user_id
          ? `/users/${f.user_id}/files`
          : `/medicalfiles/${reference}/files`;
        if (f.mime === 'application/zip') {
          const fileRefs = await fetchFileRefs(fetchUrl, f.index);
          loaded.current += f.size;
          return { mime: f.mime, refs: fileRefs };
        }
        const downloadedFile = await downloadFile(`${fetchUrl}/${f.index}`);
        return downloadedFile;
      })
    );
    return res;
  }, [downloadFile, fetchFileRefs, reference, files, loaded]);

  useEffect(() => {
    const init = async () => {
      if (files && files.length > 0) {
        setIsFetching(true);
        const newFiles = await fetchAllFiles();
        loadedFiles.current = [...newFiles];
      } else if (path) {
        setIsFetching(true);
        const newFile = await downloadFile(path);
        loadedFiles.current = [newFile];
      }
      setIsFetching(false);
    };
    init();
  }, [fetchAllFiles, setIsFetching, downloadFile, files, path, loadedFiles]);

  useEffect(() => {
    if (ref.current) ref.current.scrollLeft = ref.current.scrollWidth;
  }, [loadedFiles]);

  if (isFetching)
    return (
      <Flex style={{ minHeight: '90vh' }}>
        <Progress type="circle" percent={Math.round((loaded.current / length) * 100)} />
      </Flex>
    );

  return (
    <>
      <Title>{t('FRONT_VIEWER_LABEL')}</Title>
      <Button style={styles.back} type="primary" onClick={() => history.goBack()}>
        {t('FRONT_COMMON_BACK')}
      </Button>
      {files?.length === 0 && (
        <Typography.Paragraph>
          <blockquote>{t('FRONT_NOT_ENOUGH_PERMISSIONS')}</blockquote>
        </Typography.Paragraph>
      )}
      {loadedFiles?.current?.length > 0 && (
        <Flex forwardedRef={ref} className="viewer" style={styles.flex}>
          {loadedFiles.current.map((m, i) => {
            if (m.mime.includes('image'))
              return (
                <img
                  style={styles.image}
                  key={Math.random()}
                  alt={`file-${i}`}
                  src={`data:${m.mime};base64, ${m.file}`}
                />
              );
            if (m.mime.includes('pdf'))
              return (
                <embed
                  title="Document"
                  style={{ ...styles.image, width: '100%', minWidth: 500 }}
                  key={Math.random()}
                  src={m.url}
                />
              );
            if (m.mime.includes('zip') || m.mime.includes('dcm') || m.mime.includes('dicom'))
              return <CornerstoneViewer key={`viewer_${i}`} m={m} index={i} />;
            return null;
          })}
        </Flex>
      )}
    </>
  );
}

export default Viewer;
