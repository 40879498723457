/* eslint-disable react/prop-types */
import { Checkbox, Radio } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { fetchAnswerTypes } from '../../services/api';

function Choices({ multiple = false, question = null }) {
  const { t } = useTranslation();
  const [medicalFile] = useMedicalFile();
  const [answerTypes, setAnswerTypes] = useState([]);
  const { reference } = medicalFile;

  const loadUsers = useCallback(async () => {
    const a = await fetchAnswerTypes(reference, question).catch(() => []);
    setAnswerTypes(a);
  }, [question, reference]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <>
      {multiple ? <Checkbox.Group options={answerTypes} /> : <Radio.Group options={answerTypes} />}
    </>
  );
}

export default Choices;
