import { Form, Input, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckupType, usePathology } from '../../redux/metadata/selectors';

function SelectCheckupType() {
  const { t } = useTranslation();
  const checkupTypes = useCheckupType();

  return (
    <Form.Item
      label={t('FRONT_MEDICAL_FILE_CHECKUPTYPE')}
      name="checkup_type"
      rules={[
        {
          required: true,
          message: `${t('FRONT_MEDICAL_FILE_CHECKUPTYPE')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
    >
      {/* <Input /> */}
      <Select placeholder={t('FRONT_COMMON_SELECT')}>
        {checkupTypes.map((p, i) => (
          <Select.Option key={p.id} value={p.id}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectCheckupType;
