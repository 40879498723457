/* eslint-disable react/no-unescaped-entities */
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../services/i18n';
import EN from './PDF/legal-terms-en.pdf';
import FR from './PDF/legal-terms-fr.pdf';

function LegalTerms() {
  const { t } = useTranslation();
  return (
    <Content style={{ padding: 24 }}>
      <Title>{t('FRONT_SIDEBAR_LEGAL_TERMS')}</Title>
      <embed
        style={{ width: '100%', height: 'calc(100vh - 200px)' }}
        key={Math.random()}
        src={i18n.language === 'fr' ? FR : EN}
      />
    </Content>
  );
}

export default LegalTerms;
