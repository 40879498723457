/* eslint-disable react/prop-types */
import { Form, Divider, Select, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import languages from '../../services/languages';

function SelectLanguages({ onlyEnglishAndFrench = true, required = true }) {
  const { t } = useTranslation();
  const [allLanguages, setAllLanguages] = useState(languages);
  const [language, setLanguage] = useState('');

  const addLanguage = () => {
    if (language !== '') {
      setAllLanguages([...allLanguages, { label: language }]);
      setLanguage('');
    }
  };

  const handleLanguageChange = (ev) => {
    setLanguage(ev.target.value);
  };

  return (
    <Form.Item
      label={t('FRONT_USER_LANGUAGE')}
      name="language"
      rules={[{ required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        mode={onlyEnglishAndFrench ? '' : 'multiple'}
        showSearch
        dropdownRender={(menu) => (
          <div>
            {menu}
            {!onlyEnglishAndFrench && (
              <>
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <Input
                    style={{ flex: 'auto' }}
                    value={language}
                    onChange={handleLanguageChange}
                  />
                  <Button type="link" style={{ flex: 'none' }} onClick={addLanguage}>
                    <PlusOutlined /> {t('FRONT_FORM_OTHER')}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      >
        {allLanguages
          .filter((l) => (onlyEnglishAndFrench ? l.value === 'en' || l.value === 'fr' : l))
          .map((l) => (
            <Select.Option key={l.value} value={onlyEnglishAndFrench ? l.value : l.label}>
              {t(l.label)}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export default SelectLanguages;
