import { Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Choices from './Choices';

const { TextArea } = Input;

function FormDynamic({ questions, responses, type = 'health' }) {
  const { t } = useTranslation();
  const ls = JSON.parse(localStorage.getItem(type));
  const renderItem = (q) => {
    let initialValue =
      responses.length === 0 && ls !== null
        ? ls[q.id]
        : responses.find((r) => r[`${type}_question_id`] === q.id)?.answer;

    if (q.type === 'integer' && !initialValue) initialValue = 0;

    if (q.type === 'date')
      return (
        <Form.Item
          initialValue={moment(initialValue)}
          label={q.question}
          name={q.id}
          key={q.id}
          rules={[{ required: q.required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <DatePicker />
        </Form.Item>
      );

    if (q.answers?.length > 0 && typeof initialValue === 'string') {
      initialValue = initialValue.split(','); // prevent multiple answers
    }

    return (
      <Form.Item
        name={q.id}
        initialValue={initialValue}
        key={q.id}
        label={q.question}
        rules={[{ required: q.required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
      >
        {(() => {
          if (q.answers?.length > 0) {
            const answers = q.answers.map((a) => {
              a.value = a.value.toString();
              return a;
            });
            return <Select mode={q.multiple ? 'multiple' : ''} options={answers} />;
          }
          if (q.type === 'string') return <TextArea rows={2} />;
          if (q.type === 'integer') return <InputNumber value={0} />;
          if (q.type === 'choices') return <Choices multiple={q.multiple} question={q.id} />;
          if (q.type === 'boolean')
            return (
              <Radio.Group>
                <Radio value="1">{t('FRONT_COMMON_TRUE')}</Radio>
                <Radio value="0">{t('FRONT_COMMON_FALSE')}</Radio>
              </Radio.Group>
            );
          return null;
        })()}
      </Form.Item>
    );
  };

  return questions.map((q) => renderItem(q));
}

FormDynamic.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.any).isRequired,
  responses: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default FormDynamic;
