/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';

export const useMetadata = () => useSelector((state) => state.metadata);
export const usePathology = () => useSelector((state) => state.metadata[0]?.medicalfile.pathology);
export const useCheckupType = () => useSelector((state) => state.metadata[0]?.medicalfile.checkup);
export const useDelay = () => useSelector((state) => state.metadata[0]?.medicalfile.timeWindow);
export const useAssistance = () =>
  useSelector((state) => state.metadata[0]?.medicalfile.assistance);
export const useMedicaleFileType = () =>
  useSelector((state) => state.metadata[0]?.medicalfile.type);

export const useFileTypes = (group = null) =>
  useSelector((state) => {
    const fts = state.metadata[0]?.fileTypes;
    if (!group) return fts;
    if (fts) return fts.filter((ft) => ft.group === group);
    return [];
  });

export const useTemplate = () => useSelector((state) => state.metadata[0]?.role.templates);
export const useRolesCreate = () => useSelector((state) => state.metadata[0]?.roles_create);
export const useRoles = () => useSelector((state) => state.metadata[0]?.roles);
export const useTimeZones = () => useSelector((state) => state.metadata[0]?.misc?.timezones || []);
export const useCountries = () => useSelector((state) => state.metadata[0]?.misc?.countries || []);
export const useParentCompanies = () =>
  useSelector((state) => state.metadata[0]?.companies?.parent_value);
