import { Button, DatePicker, Form, Input, InputNumber, Switch } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import enUS from 'antd/es/date-picker/locale/en_US';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRoles } from '../redux/user/selectors';
import { isPatient } from '../services/rights';
import MobilePhone from './form/MobilePhone';
import SelectCivility from './form/SelectCivility';
import SelectCountries from './form/SelectCountries';
import SelectLanguages from './form/SelectLanguages';

function Profile() {
  const { t, i18n } = useTranslation();
  const roles = useRoles();

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  return (
    <>
      <SelectCivility />
      <Form.Item
        name="firstname"
        label={t('FRONT_USER_FIRST_NAME')}
        rules={[
          {
            required: true,
            message: `${t('FRONT_USER_FIRST_NAME')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastname"
        label={t('FRONT_USER_LAST_NAME')}
        rules={[
          {
            required: true,
            message: `${t('FRONT_USER_LAST_NAME')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="birthname"
        label={t('FRONT_USER_BIRTH_NAME')}
        rules={[
          {
            required: true,
            message: `${t('FRONT_USER_BIRTH_NAME')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <MobilePhone required={false} />
      <Form.Item label={t('FRONT_USER_ADDRESS')} name="address">
        <Input />
      </Form.Item>
      <Form.Item label={t('FRONT_USER_ZIP')} name="zipcode">
        <Input />
      </Form.Item>
      <Form.Item
        label={t('FRONT_USER_CITY')}
        name="city"
        rules={[
          {
            required: isPatient(roles),
            message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <SelectCountries keyValue="code" required />

      {isPatient(roles) && (
        <Form.Item
          label={t('FRONT_USER_BIRTH_DATE')}
          name="birthday"
          rules={[
            {
              required: true,
              message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
            },
          ]}
        >
          <DatePicker
            locale={i18n.language === 'fr' ? frFR : enUS}
            format={i18n.language === 'fr' ? dateFormatList[0] : dateFormatList[1]}
            placeholder={moment().format(
              i18n.language === 'fr' ? dateFormatList[0] : dateFormatList[1]
            )}
          />
        </Form.Item>
      )}

      <SelectLanguages onlyEnglishAndFrench={false} />

      <Form.Item
        label={
          <>
            <span>{t('FRONT_PROFILE_CGU_CONFIRM_1')}&nbsp;</span>
            <Button
              type="link"
              style={{ paddingLeft: 0, fontWeight: 'bold', fontSize: 'inherit' }}
              onClick={() => window.open('/terms', '_blank')}
            >
              {t('FRONT_PROFILE_CGU_CONFIRM_2')}
            </Button>
          </>
        }
        name="medical_advice"
        valuePropName="checked"
        rules={[
          {
            validator: (_, checked) => (checked ? Promise.resolve() : Promise.reject()),
            required: true,
            message: t('FRONT_FORM_MESSAGE_REQUIRED'),
          },
        ]}
      >
        <Switch />
      </Form.Item>
    </>
  );
}

export default Profile;
