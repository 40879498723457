import {
  FilePdfOutlined,
  FileProtectOutlined,
  FileUnknownOutlined,
  MailOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHasPermission from '../../../hooks/useHasPermission';
import { useRoles } from '../../../redux/user/selectors';
import {
  isAdmin,
  isCoordinator,
  isExpertDoctor,
  isHubDoctor,
  isLocalDoctor,
  isPatient,
} from '../../../services/rights';
import useStepActive from '../../../hooks/useSMOStepActive';

const { TabPane } = Tabs;

function SMOTabs() {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const roles = useRoles();
  const { isStep, beforeStep } = useStepActive();
  const hasPermission = useHasPermission();

  return (
    <Tabs activeKey={location.pathname} onChange={(key) => history.push(key)}>
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_FILE')}
          </>
        }
        key={`/medicals/${params.reference}`}
      />
      <TabPane
        tab={
          <>
            <UserOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_PATIENT')}
          </>
        }
        key={`/medicals/${params.reference}/patient`}
      />
      <TabPane
        tab={
          <>
            <SolutionOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_HEALTH_FORM')}
          </>
        }
        key={`/medicals/${params.reference}/health`}
      />
      <TabPane
        tab={
          <>
            <FilePdfOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_DOCUMENTS')}
          </>
        }
        key={`/medicals/${params.reference}/documents`}
      />
      <TabPane
        tab={
          <>
            <MailOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_CONTACT')}
          </>
        }
        key={`/medicals/${params.reference}/contact`}
      />
      {isExpertDoctor(roles) && (
        <TabPane
          disabled={beforeStep('doctor.stepvalidatemedicalfile')}
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_SMO')}
            </>
          }
          key={`/medicals/${params.reference}/smo`}
        />
      )}

      {!isExpertDoctor(roles) && (
        <TabPane
          disabled={beforeStep('patient.viewsmo')}
          tab={
            <>
              <MailOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_SMO')}
            </>
          }
          key={`/medicals/${params.reference}/smo/final`}
        />
      )}
      {hasPermission('view satisfactionanswers') && (
        <TabPane
          disabled={
            (!isPatient(roles) && !isStep('any.fileclosed')) ||
            (isPatient(roles) && beforeStep('patient.stepfillsatisfactionform'))
          }
          tab={
            <>
              <SmileOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_SATISFACTION_FORM')}
            </>
          }
          key={`/medicals/${params.reference}/satisfaction`}
        />
      )}
    </Tabs>
  );
}

export default SMOTabs;
