import moment from 'moment';
import { fetchCompany } from '../../services/api';
import i18n from '../../services/i18n';
import mobileCodes from '../../services/mobileCodes';
import request from '../../services/request';
import { fetchMedicalFiles } from '../medicals/actions';
import { fetchMetadata } from '../metadata/actions';
import { fetchPermissions } from '../permissions/actions';
import { fetchWorlflowUser } from '../workflowUser/actions';

export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_ERROR = 'SET_ERROR';
export const USER_IS_FETCHING = 'USER_IS_FETCHING';

export const userIsFetching = () => ({ type: USER_IS_FETCHING });
const setUser = (user) => ({ type: SET_USER, user });
export const setProfile = (profile) => ({ type: SET_PROFILE, profile });

// PROFILE
export const fetchProfile = async (userId) =>
  request(`/users/${userId}/profile`).then((p) => {
    if (p.phone) {
      const mcr = mobileCodes.find((mc) => mc.dial_code === p.phone.slice(0, -9));
      if (mcr) p.phone_country = mcr.code;
      p.phone = p.phone.slice(-9);
    }
    return p;
  });

export const fetchUser = () => async (dispatch) => {
  try {
    let { data } = await request('/me', 'GET', null, null, false); // IMPORTANT: la condition false ici permet d'éviter la redirection vers le login lorsqu'on veut savoir si un user est connecté
    dispatch(fetchPermissions(data.id));
    dispatch(fetchMedicalFiles(data.id));
    dispatch(fetchWorlflowUser(data.id));
    dispatch(fetchMetadata());
    const company = await fetchCompany(data.company.id);
    data = { ...data, company };
    dispatch(setUser(data));
    await i18n.changeLanguage(data.language);
    try {
      const profile = await fetchProfile(data.id);
      dispatch(setProfile(profile));
    } catch (err) {
      dispatch(setProfile(null));
    }
  } catch (err) {
    dispatch(setUser(null));
  }
};

export const signIn = (credentials, history, prevLocation) => async (dispatch) =>
  request('/login', 'POST', credentials).then(async () => {
    await dispatch(fetchUser());
    if (prevLocation && prevLocation !== '/login' && prevLocation !== '/logout')
      history.push(prevLocation);
    else history.push('/dashboard');
  });

export const signOut = () => async (dispatch) => {
  request('/logout', 'POST').then(() => dispatch(setUser(null)));
};

export const connectAs = (credentials, history) => async (dispatch) => {
  await request('/logout', 'POST');
  await dispatch(signIn(credentials, history));
};
