import { Button, Col, Divider, Form, Input, message, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import SelectCivility from '../../components/form/SelectCivility';
import useQuery from '../../hooks/useQuery';
import request from '../../services/request';

const styles = {
  form: { minWidth: 400, maxWidth: 500 },
  link: { paddingLeft: 0, fontWeight: 'bold', fontSize: 'inherit' },
  divider: { margin: '16px 0' },
  background: {
    backgroundImage: 'url(/bg-connexion.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  rightside: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
  },
};

function Register() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const referral = query.get('referral');
  const [state, setState] = useState({ isFetching: false, err: null });
  const [captcha, setCaptcha] = useState(null);

  const create = async (values) => {
    setState({ isFetching: true, err: null });
    return request('/register', 'POST', { referral, ...values, key: captcha.key })
      .then((response) => {
        message.success(t('FRONT_NOTIFICATION_REGISTER_SUCCESS'));
        history.push('/login');
      })
      .catch((err) => setState({ isFetching: false, err }));
  };

  const getCaptcha = async () => {
    const data = await request('/captcha/api');
    setCaptcha(data);
  };

  return (
    <Row style={{ minHeight: 'calc(100vh - 64px)' }} justify="center" align="stretch">
      <Col sm={0} md={12} style={styles.background} />
      <Col sm={24} md={12} style={styles.rightside}>
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          <Title>{t('FRONT_REGISTER_TITLE')}</Title>
          <Form style={styles.form} layout="vertical" name="register" onFinish={create}>
            <SelectCivility hasFeedback />

            <Form.Item
              name="firstname"
              label={t('FRONT_USER_FIRST_NAME')}
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastname"
              label={t('FRONT_USER_LAST_NAME')}
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={t('FRONT_USER_EMAIL')}
              rules={[
                { type: 'email' },
                { required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email_confirmation"
              label={t('FRONT_USER_EMAIL_CONFIRM')}
              rules={[
                { type: 'email' },
                { required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('FRONT_USER_EMAIL_NOTMATCH')));
                  },
                }),
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label={t('FRONT_USER_PASSWORD_NEW')}
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
              ]}
              hasFeedback
              extra={t('FRONT_USER_PASSWORD_RULES')}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="password_confirmation"
              label={t('FRONT_USER_PASSWORD_CONFIRM')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('FRONT_USER_PASSWORD_NOTMATCH')));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="captcha"
              label="Captcha"
              extra={t('FRONT_CONTACT_CAPTCHA_INFO')}
              rules={[{ required: true, message: '' }]}
            >
              <Row gutter={8}>
                {captcha !== null && (
                  <Col span={12}>
                    <img key={Math.random()} alt="captcha" src={captcha.img} />
                  </Col>
                )}
                <Col span={12}>
                  <Button onClick={getCaptcha}>
                    {captcha !== null ? t('FRONT_COMMON_RELOAD') : t('FRONT_COMMON_LOAD')} captcha
                  </Button>
                </Col>
              </Row>
              {captcha !== null && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="captcha"
                      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>

            <FormErrors err={state.err} />

            <Title level={5} style={{ lineHeight: 1 }}>
              {t('FRONT_REGISTER_CGU_CONFIRM_1')}
              <Button style={styles.link} type="link" onClick={() => history.push('/policy')}>
                &nbsp;{t('FRONT_REGISTER_CGU_CONFIRM_2')}
              </Button>
            </Title>
            <Button
              style={styles.button}
              type="primary"
              htmlType="submit"
              loading={state.isFetching}
              block
            >
              {t('FRONT_REGISTER_REGISTER')}
            </Button>

            <Divider style={styles.divider}>{t('FRONT_REGISTER_LOGIN_QUESTION')}</Divider>

            <Button
              style={styles.button}
              type="primary"
              block
              onClick={() => history.push('/login')}
            >
              {t('FRONT_REGISTER_LOGIN')}
            </Button>
          </Form>
        </Space>
      </Col>
    </Row>
  );
}

export default Register;
