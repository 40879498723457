/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Flex from '../../components/Flex';
import MedicalFileEdit from '../../components/medicalFile/Edit';
import useHasPermission from '../../hooks/useHasPermission';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import MedicalfileInfos from '../../components/infos/MedicalFileInfos';

function MedicalFileDetails({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [onEdit, setOnEdit] = useState(location.state?.edit);

  useEffect(() => {
    if (location.state?.edit) setOnEdit(true);
  }, [location.state?.edit]);
  const hasPermission = useHasPermission();

  return (
    <>
      {onEdit ? (
        <MedicalFileEdit
          medicalFile={medicalFile}
          onFinish={() => {
            setOnEdit(false);
            dispatch(fetchMedicalFileData(medicalFile.reference));
          }}
        />
      ) : (
        <MedicalfileInfos medicalFile={medicalFile} />
      )}
      {hasPermission('update medicalfiles') && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Button
            type={onEdit ? 'danger' : 'primary'}
            shape="round"
            icon={onEdit ? <ArrowLeftOutlined /> : <EditOutlined />}
            onClick={() => setOnEdit(!onEdit)}
          />
        </Flex>
      )}
    </>
  );
}

export default MedicalFileDetails;
