/* eslint-disable no-undef */
import { EyeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import Error404 from '../../components/Error404';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { useCheckupType, useMedicaleFileType, usePathology } from '../../redux/metadata/selectors';
import { fetchAllUserMedicalFiles, fetchUser } from '../../services/api';

function UserMedicalFiles() {
  const { t } = useTranslation();
  const params = useParams();
  const hasPermission = useHasPermission();
  const pathologies = usePathology();
  const type = useMedicaleFileType();
  const checkupTypes = useCheckupType();

  const userId = useMemo(() => params?.userId, [params]);

  const [[state, isFetching], setState] = useState([null, true]);
  const [user, setUser] = useState(null);

  const fetchData = useCallback(
    (page) => {
      fetchAllUserMedicalFiles(userId, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    },
    [userId]
  );

  const getUser = useCallback(() => {
    fetchUser(userId)
      .then(({ data: u }) => setUser(u))
      .catch(() => setUser(null));
  }, [userId]);

  useEffect(() => {
    getUser();
    fetchData();
  }, [fetchData, getUser]);

  const columns = [
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      fixed: 'left',
      width: 100,
      render: (value) => <Link to={`/medicals/${value}`}>{value}</Link>,
    },
    {
      title: t('FRONT_MEDICAL_FILE_TYPE'),
      dataIndex: 'type',
      responsive: ['md'],
      render: (i) => type[i],
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i, record) =>
            record.pathology !== null
              ? pathologies.find((p) => p.id === record.pathology).name
              : checkupTypes.find((p) => p.id === record.checkup_type).name,
          responsive: ['md'],
        }
      : {},
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: ['user', 'firstname'], width: 150 },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: ['user', 'lastname'], width: 150 },
    {
      title: t('FRONT_USER_COUNTRIES'),
      dataIndex: ['user', 'countries'],
      render: (values) => values.map((v) => v.name).join(', '),
      width: 150,
    },
    {
      title: t('FRONT_MEDICAL_FILE_STATUS'),
      dataIndex: ['step', 'description'],
      responsive: ['lg'],
    },
    {
      title: t('FRONT_MEDICALE_FILE_START_CHECKUP'),
      dataIndex: ['travel_date'],
      render: (r) => r && new Date(r).toLocaleString(),
      width: 150,
    },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
      responsive: ['md'],
    },
    {
      key: 'action',
      width: 50,
      render: (_, record) => (
        <Link to={`/medicals/${record.reference}`}>
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      ),
    },
  ];

  if (!userId) return <Error404 />;
  return (
    <>
      {user !== null && (
        <Title>
          {user.firstname} {user.lastname}
        </Title>
      )}
      <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
    </>
  );
}

export default UserMedicalFiles;
