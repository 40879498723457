import { useStep } from '../redux/step/selectors';

const useDermatoStepActive = () => {
  const [step] = useStep();

  const steps = [
    'patient.stepfillmedicalform',
    'admin.stepassigndoctor',
    'system.stepwaitingforcheckupdata',
    'patient.viewcheckupresults',
    'doctor.stepfillcheckupform',
    'system.fileclosed',
  ];

  const stepActive = steps.findIndex((s) => s === step?.reference);

  const beforeStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive < findIndex;
  };

  const isStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive === findIndex;
  };

  const afterStep = (reference) => {
    const findIndex = steps.findIndex((s) => s === reference);
    return stepActive > findIndex;
  };

  return { beforeStep, isStep, afterStep };
};

export default useDermatoStepActive;
